import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import { CardCarousel, Icon, SeriesCard, FloatingTryForFree, HighlightedHero } from 'src/components/shared'
import HighlightedSeriesCard from 'src/components/series/HighlightedSeriesCard'
import { isEmpty, map } from 'lodash'
import * as Styles from 'src/styles/series-page.module.scss'
import { Button } from 'semantic-ui-react'
import Selectors from 'src/state/root-selectors'
import Actions from 'src/state/root-actions'
import { ISeriesCategoryFields, ISeries } from '@pvolve/sdk/src/models/contentful_types'
import Loader from 'src/components/shared/Loader'
import Colors from 'src/utils/colors'

const Index = () => {
    const dispatch = useDispatch()
    const entitled = useSelector(Selectors.auth.entitled)
    const highlightedSeriesList = useSelector(Selectors.series.getHighlightedSeries)
    const seriesCategoryList: ISeriesCategoryFields[] = useSelector(Selectors.seriesCategory.seriesCategoryList)
    const userCohort = useSelector(Selectors.account.userCohort)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!isEmpty(highlightedSeriesList) && !isEmpty(seriesCategoryList)) {
            setLoading(false)
        }
    }, [highlightedSeriesList, seriesCategoryList])

    useEffect(() => {
        dispatch(Actions.series.loadHighlightedSeries.trigger())
        dispatch(Actions.seriesCategory.getSeriesCategory.trigger())
    }, [userCohort, dispatch])

    // NOTE: Force a refresh so that the user is redirected to Zelda
    // See: .infrastructure/web/infra.yaml
    window.location.href = '/series'
    return null

    return loading ? (
        <Loader spinnerColor={Colors.black} className="padding-top--large margin-bottom--large" />
    ) : (
        <>
            <div className={Styles.seriesPageWrapper}>
                {!isEmpty(highlightedSeriesList) && (
                    <HighlightedHero contentList={highlightedSeriesList} renderItem={HighlightedSeriesCard} />
                )}
                {!isEmpty(seriesCategoryList) &&
                    map(seriesCategoryList, (seriesCategoryInfo, i) => (
                        <div
                            className={`padding-horizontal--large ${Styles.carouselWrapper}`}
                            key={`series-category-${i}`}
                        >
                            <CardCarousel
                                naturalSlideHeight={610}
                                naturalSlideWidth={430}
                                title={seriesCategoryInfo?.name}
                                to={`/series/categories/${seriesCategoryInfo?.slug}`}
                                data={seriesCategoryInfo?.series}
                                renderItem={RenderItem}
                                description={seriesCategoryInfo?.description}
                            />
                        </div>
                    ))}
                <div className={Styles.buttonWrapper}>
                    <Button
                        as={Link}
                        to="/series/categories/all-series"
                        size="large"
                        style={{ backgroundColor: Colors.black }}
                    >
                        SEE ALL SERIES A-Z <Icon style={{ marginLeft: '8px' }} name="pv-arrow-right" size={14} />
                    </Button>
                </div>
                {!entitled && <FloatingTryForFree />}
            </div>
        </>
    )
}

const RenderItem = ({ data }: { data: ISeries }, index: number) => {
    const datum = data?.node || data
    return <SeriesCard key={`series-card-${index}`} series={datum} />
}

export default Index
